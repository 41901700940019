<template>
  <div class="header-container">
    <Logo />
    <Navbar
      :isNavOpen="isNavOpen"
      :navItems="getNavItems"
      @handleNavItemClick="handleNavItemClick"
      :activeNavItem="activeNavItem"
    />
  </div>
</template>

<script>
import Logo from "./components/logo/Logo.vue";
import Navbar from "./components/navbar/Navbar.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: {
    Logo,
    Navbar,
  },
  data() {
    return {
      isMobileDevice: false,
      isNavOpen: false,
      isActive: false,
    };
  },
  computed: {
    ...mapGetters(["getNavItems"]),
    ...mapState("dataFromDatabaseModule", {
      showMacrons: (state) => state.showMacrons,
      showTest: (state) => state.showTest,
    }),
    macronToggle() {
      return this.showMacrons ? "ā" : "a";
    },
  },
  methods: {
    ...mapActions("dataFromDatabaseModule", [
      "toggleMacrons",
      "toggleTest",
      "toggleState",
    ]),
    showForm(form) {
      this.$emit("showForm", form);
    },
    toggleMenu() {
      this.isNavOpen = !this.isNavOpen;
    },
    handleNavItemClick(item) {
      this.$emit("handleNavItemClick", item);
    },
  },
  watch: {
    $route(to) {
      this.activeNavItem = to.name || "";
    },
  },
};
</script>

<style scoped>
@import "./styles/header.css";

.settings-bar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100px;
}

.setting-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 24px;
  margin-right: 24px;
}

.setting-label {
  margin-bottom: 8px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}
</style>
