// latinModule.js

import router from "../router/index";

// Importing latin App dropdown data
import vwLatinWordsLookupData from './data/vw_latin_words_lookup.json';
import vwLatinWordTypeData from './data/vw_latin_wordtype.json';
import vwConjugationData from './data/vw_conjugation.json';
import vwDeclensionData from './data/vw_declension.json';

const dropdownSearchModule = {
  namespaced: true,
  state: {
    vw_latin_words_lookup: vwLatinWordsLookupData
      .filter(item => item.enabled === 1)
      .sort((a, b) => a.latin_desc.localeCompare(b.latin_desc)),
    activeWordType: null,
    normalizedWordTypeDesc: null,
    wordTypePath: null,
    activeInflectionalCategory: null,
    activeConjugation: null,
    activeDeclension: null,
    activeLatinWord: null,
    vw_declension: vwDeclensionData.filter(item => item.enabled === 1),
    vw_conjugation: vwConjugationData.filter(item => item.enabled === 1),
    vw_latin_wordtype: vwLatinWordTypeData,
    latinWordSearchTerm: "",
    latinWordDropdownOpen: false,
    collapseSettingsIcons: true,
    selectPaneHeight: 64,
    selectPaneRows: 1,
  },
  mutations: {
    setActiveWordType(state, wordType) {
      if (state.activeWordType !== wordType) {
        state.activeWordType = wordType;
      } else {
      }
    },
    
    setActiveDeclension(state, declension) {
      if (state.activeDeclension !== declension) {
        state.activeDeclension = declension;
      } else {
      }
    },
    
    setActiveConjugation(state, conjugation) {
      if (state.activeConjugation !== conjugation) {
        state.activeConjugation = conjugation;
      } else {
      }
    },
    
    setActiveLatinWord(state, latinWord) {
      if (state.activeLatinWord !== latinWord) {
        state.activeLatinWord = latinWord;
      } else {
      }
    },
    

    clearActiveWordType(state) {
      if (state.activeWordType !== null) {
        state.activeWordType = null;
      } else {
      }
    },
    
    clearActiveDeclension(state) {
      if (state.activeDeclension !== null) {
        state.activeDeclension = null;
      } else {
      }
    },
    
    clearActiveConjugation(state) {
      if (state.activeConjugation !== null) {
        state.activeConjugation = null;
      } else {
      }
    },
    
    clearActiveLatinWord(state) {
      if (state.activeLatinWord !== null) {
        state.activeLatinWord = null;
      } else {
      }
    },
    SET_LATIN_WORD_SEARCH_TERM(state, value) {
      state.latinWordSearchTerm = value;
    },
    TOGGLE_LATIN_WORD_DROPDOWN_OPEN(state, forceState) {
      if (forceState !== undefined) {
        state.latinWordDropdownOpen = forceState;
      } else {
        state.latinWordDropdownOpen = !state.latinWordDropdownOpen;
      }
    },
    TOGGLE_SETTINGS_PANE(state) {
      state.collapseSettingsIcons = !state.collapseSettingsIcons;
    },
    TOGGLE_SELECT_PANE_HEIGHT(state) {
      state.selectPaneHeight = state.selectPaneHeight === 64 ? 128 : 64;
    },
    TOGGLE_SELECT_PANE_ROWS(state) {
      state.selectPaneRows = state.selectPaneRows === 1 ? 2 : 1;
    },
  },
  actions: {
    setActiveConjugation({ commit, state }, conjugation) {
      commit('clearActiveDeclension');
      commit('updateActiveConjugation', conjugation);

      let matchingWordType = null;
      // If activeWordType is not null and doesn't match the conjugation's word type, find the matching word type.
      if (
        state.activeWordType &&
        state.activeWordType.l_wordtype_id !== conjugation.l_wordtype_id
      ) {
        matchingWordType = state.vw_latin_wordtype.find(
          (wt) => wt.l_wordtype_id === conjugation.l_wordtype_id
        );
      }
      // If activeWordType is null, directly find the matching word type based on the conjugation's ID.
      else if (!state.activeWordType) {
        matchingWordType = state.vw_latin_wordtype.find(
          (wt) => wt.l_wordtype_id === conjugation.l_wordtype_id
        );
      }

      // If a matching word type was found, update the activeWordType.
      if (matchingWordType) {
        commit('updateActiveWordType', matchingWordType);
      }

    },
    updateActiveWordType({ commit }, wordType) {
      commit('setActiveWordType', wordType);
    },
    setActiveWordTypeFromRoute({ commit, state }, routeParam) {
      const wordType = state.vw_latin_wordtype.find(wt => wt.l_wordtype_desc.toLowerCase() === routeParam);
      if (wordType) {
        commit('setActiveWordType', wordType);
      } else {
      }
    },
    setActiveWordType({ commit, dispatch }, wordType) {
      commit('setActiveWordType', wordType);
      if (wordType && wordType.path) {
        dispatch('navigateToPage', wordType.path);
      } else {
      }
    },    
    setActiveLatinWord({ commit, dispatch, state }, latinWord) {
      commit('setActiveLatinWord', latinWord);
      if (latinWord) {
        const wordType = state.vw_latin_wordtype.find(wt => wt.l_wordtype_id === state.activeLatinWord.l_wordtype_id);
    
        if (wordType && wordType.path) {
          
          const normalizeText = (input) => {
            if (!input) return ""; 
            const normalized = input.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
            return normalized;
          };
    
          let normalizedDesc = '';
          if (state.activeLatinWord.principal_part_2_desc || state.activeLatinWord.principal_part_1_desc) {
            normalizedDesc = normalizeText(state.activeLatinWord.principal_part_2_desc || state.activeLatinWord.principal_part_1_desc);
          }
    
          commit('setActiveWordType', wordType);
    
          // Check if all conditionals are met before dispatching and logging
          if (wordType && latinWord && wordType.path && normalizedDesc) {

            let fullPathParam = `${wordType.path}/${normalizedDesc}`;

            dispatch('navigateToPage', fullPathParam);
            
          } else {

          }
        } else {
        }
      } else {
      }
    },    
    
    clearAllDropdowns({ commit }) {
      commit('clearActiveWordType');
      commit('clearActiveDeclension');
      commit('clearActiveConjugation');
      commit('clearActiveLatinWord');
    },
    clearInflectTypeAndWord({ commit }) {
      commit('clearActiveDeclension');
      commit('clearActiveConjugation');
      commit('clearActiveLatinWord');
    },
    clearActiveLatinWord({ commit }) {
      commit('clearActiveLatinWord');
    },
    toggleSettingsPane({ commit }) {
      commit('TOGGLE_SETTINGS_PANE');
    },
    toggleSelectPaneHeight({ commit }) {
      commit('TOGGLE_SELECT_PANE_HEIGHT');
    },
    toggleSettings({ commit }) {
      commit('TOGGLE_COLLAPSE_SETTINGS_ICONS');
      commit('TOGGLE_SELECT_PANE_HEIGHT');
      commit('TOGGLE_SELECT_PANE_ROWS');
    },
    navigateToPage({ commit }, itemPath) {
      router.push(itemPath);
    },
  },

  getters: {
    getVwLatinWordsLookupData: (state) => {
      return state.vw_latin_words_lookup.filter(item => item.enabled === 1);
    },
    getActiveWordType: (state) => state.activeWordType,
    getActiveInflectionalCategory: (state) => state.activeInflectionalCategory,
    getActiveConjugation: (state) => state.activeConjugation,
    getActiveDeclension: (state) => state.activeDeclension,
    getActiveLatinWord: (state) => state.activeLatinWord,
    getVwLatinWordTypeData: (state) => {
      return state.vw_latin_wordtype.filter(item => item.enabled === 1);
    },
    getCollapseState(state) {
      return state.collapseSettingsIcons;
    },
    getSelectPaneHeight(state) {
      return state.selectPaneHeight;
    },
    getSelectPaneRows(state) {
      return state.selectPaneRows;
    },
    getFilteredConjugations: (state, getters) => {
      if (!state.activeWordType || state.activeWordType.l_wordtype_id === undefined) {
        return [];
      }
      return state.vw_conjugation.filter(item => item.l_wordtype_id === state.activeWordType.l_wordtype_id);
    },
    getFilteredDeclensions: (state, getters) => {
      if (!state.activeWordType || state.activeWordType.l_wordtype_id === undefined) {
        return [];
      }
      return state.vw_declension.filter(item => item.l_wordtype_id === state.activeWordType.l_wordtype_id);
    },
    getLatinWordsLookup: (state) => {
      let filteredData = state.vw_latin_words_lookup;

      // Filter by activeWordType if not null
      if (state.activeWordType) {
        filteredData = filteredData.filter(
          (item) => item.l_wordtype_id === state.activeWordType.l_wordtype_id
        );
      }

      // Further filter by activeDeclension if not null
      if (state.activeDeclension) {
        filteredData = filteredData.filter(
          (item) =>
            item.inflectional_category_id === state.activeDeclension.decl_id
        );
      }

      // Further filter by activeConjugation if not null
      if (state.activeConjugation) {
        filteredData = filteredData.filter(
          (item) =>
            item.inflectional_category_id === state.activeConjugation.conj_id
        );
      }

      return filteredData;
    },
    getLatinWordTypesLookup: (state) => {
      const allWordTypeIds = state.vw_latin_words_lookup.map(item => item.l_wordtype_id);
      const uniqueWordTypeIds = [...new Set(allWordTypeIds)];
      const filteredWordTypes = state.vw_latin_wordtype.filter(wordtype =>
        uniqueWordTypeIds.includes(wordtype.l_wordtype_id)
      );
      return filteredWordTypes;
    }
    
  },
};

export default dropdownSearchModule;
