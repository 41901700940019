<template>
  <div class="logo-container">
    <a class="logo" @click.prevent="navigateToPage()">
      <span class="logo-item">ClassicsHub.net</span>
    </a>
  </div>
</template>

<script>
export default {
  name: "Logo",
  methods: {
    navigateToPage() {
      this.$router.push("/");
    },
  },
};
</script>

<style>
@import "./styles/logo.css";
</style>
