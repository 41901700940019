<template>
  <nav class="navbar-container">
    <ul class="nav-list">
      <li
        v-for="item in filteredNavItems"
        :key="item.path"
        :class="[item.navClass, { 'active-nav-item': item.text === activePath }]"
        @click="calculateClick(item)"
      >
        <a href="#" @click.prevent="calculateClick(item)">
          {{ item.text }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    isNavOpen: Boolean,
    activeNavItem: String,
  },
  computed: {
    ...mapGetters(["getNavItems"]),
    activePath() {
      return this.$route.meta.text;
    },
    isMobileDevice() {
      return this.$isMobileDevice;
    },
    filteredNavItems() {
      return this.getNavItems;
    },
    numberOfFilteredNavItems() {
      return this.filteredNavItems.length;
    },
    navItemFlexBasis() {
      return `calc(100% / ${this.numberOfFilteredNavItems})`;
    },
    navListStyles() {
      return {
        "--nav-item-count": this.numberOfFilteredNavItems,
        "--nav-item-flex-basis": this.navItemFlexBasis,
      };
    },
  },
  methods: {
    navigateToPage(itemPath) {
      this.$router.push(itemPath);
    },
    calculateClick(item) {
      let itemId = item.id;
      let itemPath = item.path;
      if ([2, 3, 4].includes(itemId)) {
        return "Coming Soon";
      }
      this.navigateToPage(itemPath);
    },
  },
};
</script>

<style>
@import "./styles/Navbar.css";
</style>
