// dataFromDatabaseModule.js

import vwVerbInflectedForms from './data/latinAppData/vw_verb_inflected_forms.json';
import vwNounInflectedForms from './data/latinAppData/vw_noun_inflected_forms.json';
import vwAdjectiveInflectedForms from './data/latinAppData/vw_adjective_inflected_forms.json';
import vwPronounInflectedForms from './data/latinAppData/vw_pronoun_inflected_forms.json';

const dataFromDatabaseModule = {
  namespaced: true,
  state: {
    databaseData: {},
    showMacrons: true,
    showTest: false,
    showLongDesc: false,
    useAncientCaseSystem: false,
    showPerfectum: true,
    showImperfectum: true,
    showPresent: true,
    showImperfect: true,
    showFuture: true,
    showPerfect: true,
    showPluerfect: true,
    showFuturePerfect: true,
    showImperative: true,
    showNDInfinitives: true,
    showInfinitives: true,
    showParticiple: true,
    showGerundive: true,
    showGerundAndSupine: true,
  },
  mutations: {
    SET_DATABASE_DATA(state, payload) {
      const { viewName, data } = payload;
      // Debugging
      if (!state.databaseData[viewName]) {
        state.databaseData[viewName] = [];
      }
      state.databaseData[viewName] = data;
    },
    TOGGLE_MACRONS(state) {
      state.showMacrons = !state.showMacrons;
    },
    TOGGLE_TEST(state) {
      state.showTest = !state.showTest;
    },
    TOGGLE_DESC(state) {
      state.showLongDesc = !state.showLongDesc;
    },
    TOGGLE_CASE_SYSTEM(state) {
      state.useAncientCaseSystem = !state.useAncientCaseSystem;
    },
    toggleState(state, propertyName) {
      if (typeof state[propertyName] === 'boolean') {
        state[propertyName] = !state[propertyName];
      }
    }
  },
  actions: {
    async fetchDatabaseData({ commit, rootState }, viewName) {
      const activeLatinWord = rootState.dropdownSearchModule.activeLatinWord;

      const jsonDataMap = {
        'vw_verb_inflected_forms': vwVerbInflectedForms,
        'vw_noun_inflected_forms': vwNounInflectedForms,
        'vw_adjective_inflected_forms': vwAdjectiveInflectedForms,
        'vw_pronoun_inflected_forms': vwPronounInflectedForms,
      };

      const requiresActiveLatinWord = [
        'vw_verb_inflected_forms',
        'vw_noun_inflected_forms',
        'vw_adjective_inflected_forms',
        'vw_pronoun_inflected_forms',
      ].includes(viewName);

      if (
        requiresActiveLatinWord &&
        (!activeLatinWord || !activeLatinWord.latin_id)
      ) {
        return;
      }

      try {
        // Use the mapped JSON data
        let data = jsonDataMap[viewName] || [];
    
        // Apply filtering based on the activeLatinWord with a latin_id
        if (requiresActiveLatinWord && activeLatinWord && activeLatinWord.latin_id) {
          data = data.filter(item => item.latin_id === activeLatinWord.latin_id);
        }
    
        commit('SET_DATABASE_DATA', {
          viewName,
          data,
        });
      } catch (error) {
        console.error('An error occurred while handling the data:', error);
      }
    },
    toggleMacrons({ commit }) {
      commit('TOGGLE_MACRONS');
    },
    toggleTest({ commit }) {
      commit('TOGGLE_TEST');
    },
    toggleDescLength({ commit }) {
      commit('TOGGLE_DESC');
    },
    toggleCaseSystem({ commit }) {
      commit('TOGGLE_CASE_SYSTEM');
    },
    toggleState({ commit }, propertyName) {
      commit('toggleState', propertyName);
    }
  },
  getters: {
    getShowTest(state) {
      return state.showTest;
    },
    getShowMacrons(state) {
      return state.showMacrons;
    },
    getDescLength(state) {
      return state.showLongDesc;
    },
    getCaseSystem(state) {
      return state.useAncientCaseSystem;
    },
    getVerbParadigm: (state) => (verbParadigmId) => {
      if (state.databaseData && state.databaseData['vw_verb_inflected_forms']) {
        // Filter data based on the verbParadigmId, and exceptionality for paradigm id 7133
        let data = state.databaseData['vw_verb_inflected_forms'].filter(
          (item) => item.verb_paradigm_id === verbParadigmId
        );
    
        // Apply ancient case system filters and sorting only if the ID is not 7133
        if (verbParadigmId !== 7133 || verbParadigmId !== 7111) {
          if (state.useAncientCaseSystem) {
            data = data.filter(item => item.isAncientCase !== 0);
            data.sort((a, b) => a.ancientOrder - b.ancientOrder);
          }
        }
    
        // Convert Macrons if disabled
        if (!state.showMacrons) {
          data = data.map((item) => ({
            ...item,
            inflected_word_desc: item.inflected_word_desc
              ? item.inflected_word_desc
                  .replace(/ā/g, 'a')
                  .replace(/ē/g, 'e')
                  .replace(/ī/g, 'i')
                  .replace(/ō/g, 'o')
                  .replace(/ū/g, 'u')
              : null,
          }));
        }
    
        return data;
      } else {
        return null;
      }
    },
    getVerbForm: (state) => (latin_verb_form_id) => {
      if (state.databaseData && state.databaseData['vw_verb_inflected_forms']) {
        const forms = state.databaseData['vw_verb_inflected_forms'].filter(
          (form) => form.latin_verb_form_id === latin_verb_form_id
        );

        if (!state.showMacrons) {
          return forms.map((form) => ({
            ...form,
            inflected_word_desc: form.inflected_word_desc
              ? form.inflected_word_desc
                  .replace(/ā/g, 'a')
                  .replace(/ē/g, 'e')
                  .replace(/ī/g, 'i')
                  .replace(/ō/g, 'o')
                  .replace(/ū/g, 'u')
              : null,
          }));
        }
        return forms;
      } else {
        return null;
      }
    },

    getNestedVerbParadigm: (state) => (verbParadigmId) => {    
      if (state.databaseData && state.databaseData['vw_verb_inflected_forms']) {
        let data = state.databaseData['vw_verb_inflected_forms'].filter(
          (item) => item.verb_paradigm_id === verbParadigmId
        );
    
        // Apply ancient case system filters and sorting if the ancient case system is used and verb_paradigm_id is not 7111
        if (verbParadigmId !== 7133 && verbParadigmId !== 7111) {
          if (state.useAncientCaseSystem) {
            data = data.filter(item => item.isAncientCase !== 0);
            data.sort((a, b) => a.ancientOrder - b.ancientOrder);
            console.log(`Data after applying ancient case system filters and sorting:`, data);
          }
        }

        const grouped = data.reduce((acc, item) => {
          // Determine the formId based on whether the ancient case system is used, unless verbParadigmId is 7111
          const formId = (verbParadigmId === 7111) ? item.latin_verb_form_id : (state.useAncientCaseSystem ? item.ancientOrder : item.latin_verb_form_id);
          if (!acc[formId]) { 
            acc[formId] = [];
          }
        
          const newItem = { ...item };
          if (!state.showMacrons && newItem.inflected_word_desc) {
            newItem.inflected_word_desc = newItem.inflected_word_desc
              .replace(/ā/g, 'a')
              .replace(/ē/g, 'e')
              .replace(/ī/g, 'i')
              .replace(/ō/g, 'o')
              .replace(/ū/g, 'u');
          }
          acc[formId].push(newItem);
          console.log(`Updated accumulator for formId (${formId}):`, acc[formId]);
        
          return acc;
        }, {});
        
    
        console.log('Final grouped data:', grouped);
        return grouped;
      } else {
        console.error('vw_verb_inflected_forms data is not available.');
        return null;
      }
    },
    
       

    getAdjectiveParadigm: (state) => (adjectiveParadigmId) => {
      if (
        state.databaseData &&
        state.databaseData['vw_adjective_inflected_forms']
      ) {

        let data = state.databaseData['vw_adjective_inflected_forms'].filter(
          (item) => item.adjective_paradigm_id === adjectiveParadigmId &&
                    (!state.useAncientCaseSystem || item.isAncientCase !== 0)
        );
    
        if (state.useAncientCaseSystem) {
          data.sort((a, b) => a.ancientOrder - b.ancientOrder);
        }
    
        if (!state.showMacrons) {
          data = data.map((item) => ({
            ...item,
            inflected_word_desc: item.inflected_word_desc
              ? item.inflected_word_desc
                  .replace(/ā/g, 'a')
                  .replace(/ē/g, 'e')
                  .replace(/ī/g, 'i')
                  .replace(/ō/g, 'o')
                  .replace(/ū/g, 'u')
              : null,
          }));
        }
        return data;
      } else {
        return null;
      }
    },
    

    getNestedAdjectiveParadigm: (state) => (adjectiveParadigmId) => {
      if (
        state.databaseData &&
        state.databaseData['vw_adjective_inflected_forms']
      ) {

        let filteredData = state.databaseData['vw_adjective_inflected_forms'].filter(
          (item) => item.adjective_paradigm_id === adjectiveParadigmId &&
                    (!state.useAncientCaseSystem || item.isAncientCase !== 0)
        );
    
        if (state.useAncientCaseSystem) {
          filteredData.sort((a, b) => a.ancientOrder - b.ancientOrder);
        }
    
        const grouped = filteredData.reduce((acc, item) => {
          const formId = state.useAncientCaseSystem ? item.ancientOrder : item.latin_adjective_form_id;
          if (!acc[formId]) {
            acc[formId] = [];
          }
    
          const newItem = { ...item };
          if (!state.showMacrons && newItem.inflected_word_desc) {
            newItem.inflected_word_desc = newItem.inflected_word_desc
              .replace(/ā/g, 'a')
              .replace(/ē/g, 'e')
              .replace(/ī/g, 'i')
              .replace(/ō/g, 'o')
              .replace(/ū/g, 'u');
          }
          acc[formId].push(newItem);
    
          return acc;
        }, {});
    
        return grouped;
      } else {
        return null;
      }
    },
    

    getNounParadigm: (state) => (nounParadigmId) => {
      if (state.databaseData && state.databaseData['vw_noun_inflected_forms']) {
        let data = state.databaseData['vw_noun_inflected_forms'].filter(item => {
          return item.noun_paradigm_id === nounParadigmId &&
            (!state.useAncientCaseSystem || item.isAncientCase !== 0);
        });
    
        if (state.useAncientCaseSystem) {
          data.sort((a, b) => a.ancientOrder - b.ancientOrder);
        }
    
        if (!state.showMacrons) {
          data = data.map(item => ({
            ...item,
            inflected_word_desc: item.inflected_word_desc
              ? item.inflected_word_desc
                  .replace(/ā/g, 'a')
                  .replace(/ē/g, 'e')
                  .replace(/ī/g, 'i')
                  .replace(/ō/g, 'o')
                  .replace(/ū/g, 'u')
              : null,
          }));
        }
    
        return data;
      } else {
        return null;
      }
    },
      

    getNestedNounParadigm: (state) => (nounParadigmId) => {
      if (state.databaseData && state.databaseData['vw_noun_inflected_forms']) {

        let filteredData = state.databaseData['vw_noun_inflected_forms']
          .filter(item => item.noun_paradigm_id === nounParadigmId &&
                          (!state.useAncientCaseSystem || item.isAncientCase !== 0));
    
        if (state.useAncientCaseSystem) {
          filteredData.sort((a, b) => a.ancientOrder - b.ancientOrder);
        }
    
        const grouped = filteredData.reduce((acc, item) => {
          const formId = state.useAncientCaseSystem ? item.ancientOrder : item.lu_noun_form_id;
          if (!acc[formId]) {
            acc[formId] = [];
          }
    
          const newItem = { ...item };
          if (!state.showMacrons && newItem.inflected_word_desc) {
            newItem.inflected_word_desc = newItem.inflected_word_desc
              .replace(/ā/g, 'a')
              .replace(/ē/g, 'e')
              .replace(/ī/g, 'i')
              .replace(/ō/g, 'o')
              .replace(/ū/g, 'u');
          }
          acc[formId].push(newItem);
    
          return acc;
        }, {});
    
        return grouped;
      } else {
        return null;
      }
    },
    
    getPronounParadigm: (state) => (pronounParadigmId) => {
      if (
        !state.databaseData ||
        !state.databaseData['vw_pronoun_inflected_forms']
      ) {
        return null;
      }
    
      let data = state.databaseData['vw_pronoun_inflected_forms'].filter(
        (item) => item.pronoun_paradigm_id === pronounParadigmId &&
                  (!state.useAncientCaseSystem || item.isAncientCase !== 0)
      );
    
      if (state.useAncientCaseSystem) {
        data.sort((a, b) => a.ancientOrder - b.ancientOrder);
      }
    
      // Handle macrons
      if (!state.showMacrons) {
        data = data.map((item) => ({
          ...item,
          inflected_word_desc: item.inflected_word_desc
            ? item.inflected_word_desc
                .replace(/ā/g, 'a')
                .replace(/ē/g, 'e')
                .replace(/ī/g, 'i')
                .replace(/ō/g, 'o')
                .replace(/ū/g, 'u')
            : null,
        }));
      }
    
      return data;
    },
    

    getNestedPronounParadigm: (state) => (pronounParadigmId) => {
      if (
        !state.databaseData ||
        !state.databaseData['vw_pronoun_inflected_forms']
      ) {
        return null;
      }
    
      let data = state.databaseData['vw_pronoun_inflected_forms'].filter(
        (item) => item.pronoun_paradigm_id === pronounParadigmId &&
                  (!state.useAncientCaseSystem || item.isAncientCase !== 0)
      );
    
      if (state.useAncientCaseSystem) {
        data.sort((a, b) => a.ancientOrder - b.ancientOrder);
      }
    
      const grouped = data.reduce((acc, item) => {
        const formId = state.useAncientCaseSystem ? item.ancientOrder : item.latin_pronoun_form_id;
        if (!acc[formId]) {
          acc[formId] = [];
        }
    
        const newItem = { ...item };
        if (!state.showMacrons && newItem.inflected_word_desc) {
          newItem.inflected_word_desc = newItem.inflected_word_desc
            .replace(/ā/g, 'a')
            .replace(/ē/g, 'e')
            .replace(/ī/g, 'i')
            .replace(/ō/g, 'o')
            .replace(/ū/g, 'u');
        }
        acc[formId].push(newItem);
    
        return acc;
      }, {});
    
      return grouped;
    },
    
  },
};

export default dataFromDatabaseModule;
