import { createApp } from 'vue';
import App from './App.vue';
import store from './store/store.js';
import router from './router/index.js';
import IconSvg from '@/components/utils/IconSvg.vue';
import DirectIconSvg from '@/components/utils/DirectIconSvg.vue';

const app = createApp(App);

app.component('icon-svg', IconSvg);
app.component('d-icon-svg', DirectIconSvg);

app.config.warnHandler = (warning, vm, trace) => {
  if (warning.includes('[Vue warn]: Failed to resolve component: ion-icon')) {
    return;
  }
};

app.use(router);
app.use(store);

app.mount('#app');
