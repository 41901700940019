// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';

// Importing the navItems.json file
import navItems from '../store/data/navItems.json';

// Auto-generating dynamic routing by extracting the routes from navItems.json
const routesFromNavItems = navItems
  .filter(item => item.published && item.path && item.component)
  .map(item => ({
    path: item.path,
    name: item.name,
    component: () => import(`../views/${item.component}.vue`),
    meta: {
      id: item.id,
      text: item.text,
    }
  }));

const router = createRouter({
  history: createWebHistory(),
  routes: [...routesFromNavItems],
});

export default router;
