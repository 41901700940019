<template>
  <footer class="footer-container">
    <div class="footer-content">
      <span class="footer-left">CLAR2000 UNUK</span>
      <span class="footer-center">2023-24</span>
      <span class="footer-right">20459092</span>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
@import "./styles/footer.css";
</style>
