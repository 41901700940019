<template>
    <div class="container">
    <Header />
    <div class="content">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header/Header.vue"
import Footer from "./components/Footer/Footer.vue"

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
}
</script>

<style scoped>
@import "./assets/styles/style.css";
</style>

