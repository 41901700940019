import { createStore } from 'vuex';
import dropdownSearchModule from './dropdownSearchModule';
import dataFromDatabaseModule from './dataFromDatabaseModule';

// Imported JSON data
import vwVoiceData from './data/vw_voice.json';
import vwMoodData from './data/vw_mood.json';
import vwTenseData from './data/vw_tense.json';
import vwNumberData from './data/vw_number.json';
import vwPersonData from './data/vw_person.json';
import vwCaseData from './data/vw_case.json';
import vwGenderData from './data/vw_gender.json';
import vwDeclensionStructureData from './data/vw_declension_structure.json';
import vwDeclensionMapData from './data/vw_declension_map.json';

import vwLatinSubtypeData from './data/vw_latin_subtype.json';
import vwLatinWordTypeSubTypeData from './data/vw_latin_wordtype_subtype.json';
import vwFormTypeData from './data/vw_form_type.json';
import vwNonFiniteTypeData from './data/vw_non_finite_type.json';
import vwLatinPartData from './data/vw_latin_part.json';
import vwVerbParadigmData from './data/vw_verb_paradigm.json';

import navItemsData from './data/navItems.json';

export default createStore({
  modules: {
    dropdownSearchModule: dropdownSearchModule,
    dataFromDatabaseModule: dataFromDatabaseModule,
  },
  state: {
    numberOfPanels: 1,
    cellHeight: 22,
    fontSize: 1,
    vw_voice: vwVoiceData,
    vw_mood: vwMoodData,
    vw_tense: vwTenseData,
    vw_number: vwNumberData,
    vw_person: vwPersonData,
    vw_case: vwCaseData,
    vw_gender: vwGenderData,
    vw_declension_structure: vwDeclensionStructureData,
    vw_declensionMap: vwDeclensionMapData,

    vw_latin_subtype: vwLatinSubtypeData,

    vw_latin_wordtype_subtype: vwLatinWordTypeSubTypeData,
    vw_form_type: vwFormTypeData,
    vw_non_finite_type: vwNonFiniteTypeData,
    vw_latin_part: vwLatinPartData,
    vw_verb_paradigm: vwVerbParadigmData,
    navItems: navItemsData,
    vw_latin_words: [],
    dataFromDatabase: {},
    adjectiveData: [],
    message: ''
  },
  mutations: {
    ADD_HEIGHT(state) {
      state.cellHeight += 2;
    },
    SUBTRACT_HEIGHT(state) {
      if (state.cellHeight > 14) {
        state.cellHeight -= 2;
        if (state.cellHeight < 14) {
          state.cellHeight = 14;
        }
      } else {
      }
    },
    INCREASE_FONT_SIZE(state) {
      state.fontSize += 0.1;
    },
    DECREASE_FONT_SIZE(state) {
      if (state.fontSize > 0.6) {
        state.fontSize -= 0.1;
        if (state.fontSize < 0.6) {
          state.fontSize = 0.6;
        }
      } else {
      }
    }
  },
  actions: {

    updateCellHeight({ commit }, operation) {
      if (operation === '+') {
        commit('ADD_HEIGHT');
      } else if (operation === '-') {
        commit('SUBTRACT_HEIGHT');
      }
    },
    updateFontSize({ commit }, operation) {
      if (operation === '+') {
        commit('INCREASE_FONT_SIZE');
      } else if (operation === '-') {
        commit('DECREASE_FONT_SIZE');
      }
    },
    updateCellHeightAndFontSize({ dispatch }, operation) {
      dispatch('updateCellHeight', operation);
      dispatch('updateFontSize', operation);
    }
  },
  getters: {
    getCellHeight: (state) => state.cellHeight,
    getFontSize: (state) => state.fontSize,
    getNumberOfPanels: (state) => state.numberOfPanels,
    getVwVoice: (state) => state.vw_voice,
    getVwMood: (state) => state.vw_mood,
    getVwTense: (state) => state.vw_tense,
    getVwNumber: (state) => state.vw_number,
    getVwPerson: (state) => state.vw_person,
    getVwCase: (state) => state.vw_case,
    getVwGender: (state) => state.vw_gender,
    getVwDeclensionStructure: (state) => state.vw_declension_structure,
    getVwDeclensionMap: (state) => state.vw_declensionMap,
    getVwDeclensionData: (state) => state.vw_declension,
    getVwConjugationData: (state) => state.vw_conjugation,
    getVwLatinSubtypeData: (state) => state.vw_latin_subtype,

    getVwLatinWordTypeSubTypeData: (state) => state.vw_latin_wordtype_subtype,
    getVwFormTypeData: (state) => state.vw_form_type,
    getVwNonFiniteTypeData: (state) => state.vw_non_finite_type,
    getVwLatinPartData: (state) => state.vw_latin_part,
    getVwVerbParadigmData: (state) => state.vw_verb_paradigm,
    getMessage: state => state.message,
    getNavItems: (state) =>
      state.navItems.filter((item) => item.published && item.showInNavbar),
    getDataFromDatabase: (state) => (viewName) => {
      return state.dataFromDatabase[viewName] || [];
    },
    filteredDeclensions: (state) => {
      if (state.dataFromDatabase.vw_adjective_inflections && Array.isArray(state.dataFromDatabase.vw_adjective_inflections)) {
        const declIdsFromDatabase = new Set(state.dataFromDatabase.vw_adjective_inflections.map(item => item.decl_id));

        return state.vw_declension.filter(declension => declIdsFromDatabase.has(declension.decl_id));
      } else {
        return [];
      }
    },
  },
});
