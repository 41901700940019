<template>
  <span>
    <ion-icon :name="iconName"></ion-icon>
  </span>
</template>

<script>
export default {
  props: ["iconName"],
};
</script>
